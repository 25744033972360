import React from 'react';
import autoBind from 'react-autobind';
import api            from 'services/Api/Api.js';
import ApButton       from 'common/ApButton/ApButton.js';
import { ApInput }    from 'common/ApInput/ApInput.js';
import { tr } from 'services/Helpers/Helpers.js'
import moment from 'moment';

import './DataEdit.css';

class DataEdit extends React.Component {

    constructor(props)
    {
        super(props);
        autoBind(this);
    }

    handleChange(value)
    {
        //console.log('value: ', value);
        //this.props.onChange({[e.target.name]: e.target.value});
        //this.props.onChange({changed: true});
    }

    renderSender()
    {
        return <div>
            <h3 className="apFormHeader"> { tr('sender') } </h3>
            <ApInput
                name='sender'
                label={ tr('sending_storage') }
                value={ this.props.senderName ? this.props.senderName : '' }
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
             <h3 className="apFormHeader"> { tr('recipient') } </h3>
             <ApInput
                name='rname'
                label={ tr('name') }
                value= {this.props.receiverName ? this.props.receiverName : ''}
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
            <ApInput
                label={ tr('address') }
                value= {this.props.receiverAddress ? this.props.receiverAddress : ''}
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
            <ApInput
                label={ tr('postal_code') }
                value= {this.props.receiverZipcode ? this.props.receiverZipcode : ''}
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
            <ApInput
                label={ tr('post_office') }
                value= {this.props.receiverCity ? this.props.receiverCity : ''}
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
            <ApInput
                label={ tr('country') }
                value= {this.props.receiverCountry ? this.props.receiverCountry : ''}
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
            <ApInput
                label={ tr('contact') }
                value= {this.props.receiverContactName ? this.props.receiverContactName : ''}
                disabled={true}
                loading={this.props.loading}
            ></ApInput>
        </div>
    }

    handleReceiverTypeChange( e )
    {
        this.props.onChange( this.nullReceiver() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    getState(state)
    {
        let ret = tr('no_info');
        switch (state) 
        {
            case 1: ret = tr('waiting_for_approval1')
                break;
            case 2: ret = tr('approved_waiting_collection')
                break;
            case 3: ret = tr('collected')
                break;
            case 4: ret = tr('sent')
                break;
            case 5: ret = tr('arrived')
                break;
            case 6: ret = tr('archived')
                break;
        }
        return ret;
    }

    /*changeStatus(value)
    {
        this.setState({status : value});
        alert('nyt');
        //this.props.status = value;
    }*/

    /*save()
    {   
        let nro = this.props.number;
        let status = this.state.status;
        //console.log('dId: ', dId);
        //console.log('status: ', status);

        api({
            method: 'post',
            url: `project/delivery/updatestate/${nro}/`,

        }).then((response) => {
            console.log('tila päivitetty', response );
            this.setState({changed : false})
        }).catch((error) => {
            //errorPopper(error, 'Tilan päivityksessä tapahtui virhe');
        });
    }*/

    /*renderReceiver()
    {
        return <div>
            <h3 className="apFormHeader">Vastaanottaja</h3>

            <ReceiverDataSelect

                receiverLocked={ this.props.receiverLocked }
                receiverTitle={ this.props.receiverTitle }
                receiverType={  this.props.receiverType }
                receiverId={    this.props.receiverId }

                receiverName= {        this.props.delivery_name }
                receiverAddress={     this.props.receiverAddress }
                receiverZipcode={     this.props.receiverZipcode }
                receiverCity={        this.props.receiverCity }
                receiverCountry={     this.props.receiverCountry }
                receiverContactName={ this.props.receiverContactName }
            />
        </div>
    }*/

    renderDetails()
    {
        const events = this.props.events;
        const kuittaukset = events.map(( c, cIndex ) => {
            return <tr key={ c.id } className={ "c" }>
                <td width= "250" className="id">
                    {  tr(c.eType)  }
                </td>
                <td width= "200" className="id">
                    {  c.ePerson  }
                </td>
                <td width= "200" className="id">
                    {  moment(c.eDate).format("L HH:mm") }
                </td>
                </tr>
        });
 
        //console.log('props: ', this.props);
        let ti = this.getState(this.props.status);
        let upd = moment(this.props.upd).format('L HH:mm');
        let up = `, ${tr('updated_at').toLowerCase()} `.concat(upd);
        let tila = ti.concat(up);
        return <div>
            <h3 className="apFormHeader">{ tr('other_info') }</h3>
            <ApInput
                type="text"
                id="number"
                name="number"
                label={ tr('number') }
                disabled={ true }
                value={ this.props.number }
            />
            <ApInput
                type="textarea"
                id="comment"
                name="comment"
                label={ tr('comment') }
                autoComplete="off"
                // disabled={ true }
                value={ this.props.comment }
                onChange={ ( e ) => this.props.handleComment(e.target.value)} 
                //onChange={this.handleChange(e.value)} 
            />
            <ApInput
                type="text"
                id="state"
                name="state"
                label={ tr('status') }
                disabled={ true }
                value={ tila }
            />
            <ApButton
                onClick= {this.doList}
                color="blue"
            >
                { tr('collection_list') }
            </ApButton>

            <table>
                <tr><td><b>{ tr('event') }</b></td><td><b>{ tr('creator') }</b></td><td><b>{ tr('date') }</b></td></tr>
                { kuittaukset }
            </table>
        </div>
    }

    doList()
    {
        let kLista = `<h3>${tr('collection_list')}</h3><br>`; 
        //const date = new Date.now();
        kLista = kLista.concat(`${tr('date')}: `, moment().format('DD.MM.YYYY'));
        kLista = kLista.concat(`<br>${tr('sender')}: `,this.props.senderName);
        kLista = kLista.concat(`<br><br>${tr('recipient')}: `,this.props.receiverName);
        kLista = kLista.concat('<br>',this.props.receiverAddress);
        kLista = kLista.concat('<br>',this.props.receiverZipcode);
        kLista = kLista.concat('<br>',this.props.receiverCity);
        if(this.props.receiverCountry!=null)
            kLista = kLista.concat('<br>',this.props.receiverCountry);
        if(this.props.receiverContactName!=null)
            kLista = kLista.concat('<br>',this.props.receiverContactName);
        let comps = this.props.data;
        kLista = kLista.concat(`<br><br><table><tr><td width=300><b>${tr('component')}</b</td><td align=right><b>${tr('amount')}</b></td><td><b>${tr('from_storage_location')}</b></td></tr>`)
        comps.map(( c, cIndex ) => {
            const location = c.locations.find(location => location.id === c.delivery_from_id);
            kLista = kLista.concat('<tr><td>' + c.name + '</td>');
            kLista = kLista.concat('<td width=100 align=right>' + c.delivery_count + '</td>');
            kLista = kLista.concat('<td width=200>' + (location ? location.name : '') + '</td>');
            kLista = kLista.concat('<td width=200>_________________________</td></tr>');
        });
        kLista = kLista.concat('</table><br><br><br>_________________________________________________________________________');
        var popupWin = window.open(tr('collection_list'), 'PrintWindow','width=650,height=650,location=no,left=200px');
        popupWin.document.write(kLista);
        popupWin.print();
        popupWin.close();
    }

    render()
    {
        let summaryButton = null;
        if( this.props.summary )
        {
            summaryButton = <div className="summary-button-container">
                <ApButton
                    size="xtiny"
                    color="white"
                    className="summary-button"
                    onClick={ this.props.onSummaryClick }
                >{ tr('summary') }</ApButton>
            </div>
        }

        let sender = this.renderSender();
        //let receiver = this.renderReceiver();
        let details  = this.renderDetails();

        return <div id="po-data-edit">
            { summaryButton }
            <div className="apForm">
                <div className="apFormColumn">
                    { sender }
                   
                </div>
                <div className="apFormColumn">
                    { details }
                </div>
            </div>
        </div>
    }
}

export default DataEdit;
